import Banner from "../../sections/common/banner";
import { bannerData } from "../../../globals/banner";

function PrivacyContent() {
    return (
        <>
            <div className="section-full p-t100 p-b0 sx-bg-white sx-faq--outer">
                <div className="container">
                    <div className="aon-faq-row-section">
                        {/* Detail SECTION START */}
                        <div className="privacy-policy-content">
                            <h2>Privacy Policy</h2>
                            <p>
                                At Tech Enterprise, we are committed to protecting your privacy and ensuring that your personal information is handled securely and responsibly. This Privacy Policy outlines how we collect, use, protect, and share your information when you interact with our website, products, or services. By using our website, you consent to the terms of this Privacy Policy.
                            </p>

                            <h3>1. Information We Collect</h3>
                            <p>We may collect the following types of information to provide better services:</p>
                            <ul>
                                <li>
                                    <strong>Personal Information:</strong> Your name, email address, phone number, and other information you provide when you fill out forms, create an account, or contact us directly.
                                </li>
                                <li>
                                    <strong>Usage Data:</strong> Data about your interactions with our website, such as IP address, browser type, pages visited, session duration, and referring URLs.
                                </li>
                                <li>
                                    <strong>Cookies and Tracking Technologies:</strong> Cookies, web beacons, and similar technologies to understand your preferences and improve the website experience.
                                </li>
                                <li>
                                    <strong>Payment Information:</strong> Payment details submitted during transactions are securely processed by third-party payment processors. We do not store credit card information.
                                </li>
                                <li>
                                    <strong>Telephone Numbers:</strong> If you provide a phone number, it may be used to contact you regarding inquiries, updates, and promotions, with your consent.
                                </li>
                            </ul>

                            <h3>2. How We Use Your Information</h3>
                            <p>We use the information collected for the following purposes:</p>
                            <ul>
                                <li>Deliver and manage the services you request.</li>
                                <li>Enhance your experience on our website through personalization.</li>
                                <li>Communicate with you via email, SMS, or phone about updates, offers, or support requests.</li>
                                <li>Analyze trends and user behavior to improve functionality and design.</li>
                                <li>Ensure compliance with legal obligations and prevent fraudulent activities.</li>
                            </ul>

                            <h3>3. Consent for Communication</h3>
                            <p>
                                By providing a telephone number and submitting any form on our website, you consent to being contacted by SMS text message. Message and data rates may apply. You may reply "STOP" to opt-out of further messaging. Your mobile information will not be shared with third parties or affiliates for marketing or promotional purposes, except for text messaging originator opt-in data and consent.
                            </p>

                            <h3>4. Sharing Your Information</h3>
                            <p>We may share your information in the following circumstances:</p>
                            <ul>
                                <li>
                                    <strong>With Service Providers:</strong> To trusted third parties who assist us in delivering services, processing transactions, or analyzing usage trends.
                                </li>
                                <li>
                                    <strong>Legal Requirements:</strong> When required to comply with the law, respond to legal processes, or protect our rights and interests.
                                </li>
                                <li>
                                    <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of our business assets, your data may be transferred to the new entity.
                                </li>
                            </ul>

                            <h3>5. Data Security</h3>
                            <p>
                                We employ advanced security measures, including encryption, secure servers, and strict access controls, to safeguard your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure. We encourage you to take precautions to protect your data, such as using strong passwords and logging out after using shared devices.
                            </p>

                            <h3>6. Your Rights</h3>
                            <p>As a user, you are entitled to the following rights regarding your personal information:</p>
                            <ul>
                                <li>
                                    <strong>Right to Access:</strong> Request a copy of the data we hold about you.
                                </li>
                                <li>
                                    <strong>Right to Rectification:</strong> Request corrections to inaccurate or incomplete data.
                                </li>
                                <li>
                                    <strong>Right to Erasure:</strong> Request the deletion of your data under certain circumstances.
                                </li>
                                <li>
                                    <strong>Right to Restrict Processing:</strong> Request the restriction of how your data is used.
                                </li>
                                <li>
                                    <strong>Right to Object:</strong> Opt-out of receiving direct marketing communications.
                                </li>
                            </ul>

                            <h3>7. Cookies and Tracking</h3>
                            <p>
                                We use cookies to enhance your user experience. You can manage or disable cookies through your browser settings. Disabling cookies may affect the functionality of certain features on our website.
                            </p>

                            <h3>8. Third-Party Links</h3>
                            <p>
                                Our website may contain links to third-party sites. We are not responsible for their privacy practices or content. Please review their privacy policies when accessing their sites.
                            </p>

                            <h3>9. Changes to This Policy</h3>
                            <p>
                                This Privacy Policy may be updated periodically. Changes will be reflected on this page with the revised "Effective Date." We encourage you to review the policy regularly to stay informed.
                            </p>

                            <h3>10. Contact Us</h3>
                            <p>
                                If you have questions or concerns about this Privacy Policy, please contact us:
                                <br />
                                Email: info@techenterprise.com
                                <br />
                                Phone: (586) 294-7771
                                <br />
                                Address: 31375 Harper Ave, St Clair Shores, MI 48082
                            </p>
                        </div>
                        {/* Detail SECTION END */}
                    </div>
                </div>
            </div>
        </>
    );
}

function PrivacyPolicy() {
    return (
        <>
            <Banner _data={bannerData.privacypolicy} />
            <PrivacyContent />
        </>
    );
}

export default PrivacyPolicy;
