import React, { useState } from "react";
import { bannerData } from "../../../globals/banner";
import Banner from "../../sections/common/banner";

function ContactUsPage() {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        company: "",
        description: "",
        consent: false,
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const zohoFormData = new FormData();
        zohoFormData.append("First Name", formData.firstName);
        zohoFormData.append("Last Name", formData.lastName);
        zohoFormData.append("Email", formData.email);
        zohoFormData.append("Phone", formData.phone);
        zohoFormData.append("Company", formData.company);
        zohoFormData.append("Description", formData.description);
        zohoFormData.append("xnQsjsdp", "978185b905fb31c9ad64f54641c82f98919c9cf6ba5893c861c3552f59f9de96");
        zohoFormData.append("xmIwtLD", "dde0cf285d736c7d9a77ce26011875595aecb2792440d81f1d7e5f0f9d9ab9cb0a80f0ef277336bc5fd5dd3ddd1cccff");
        zohoFormData.append("actionType", "TGVhZHM=");
        zohoFormData.append("returnURL", "null");


        try {
            const response = await fetch("https://crm.zoho.com/crm/WebToLeadForm", {
                method: "POST",
                body: zohoFormData,
            });

            if (response.ok) {
                alert("Form submitted successfully!");
                setFormData({
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    company: "",
                    description: "",
                    consent: false,
                });
            } else {
                alert("Form submission failed. Please try again.");
            }
        } catch (error) {
            alert("An error occurred. Please try again.");
        }
    };

    return (
        <>
      <Banner _data={bannerData.contact} />

{/* Contact us START */}
<div className="section-full p-t110 p-b80 sx-bg-white sx-ourteam-outer ">
  <div className="container">
    {/* GOOGLE MAP & CONTACT FORM */}
    <div className="section-content">
      {/* CONTACT FORM */}
      <div className="row justify-content-center">
        <div className="col-lg-8 col-md-12  m-b30">
          <div className="contact-info">
            {/* TITLE START */}
            <div className="section-head left">
              <div className="sx-head-s-title">Contact Us</div>
              <div className="sx-head-l-title">
                <h2 className="sx-title">
                Get in Touch with Us for Innovative Solutions and Support
                </h2>
              </div>
            </div>
            {/* TITLE END */}
            <div className="row sx-contact-form-icon-bx">
              <div className="col-lg-6 col-md-6 m-b30">
                <div className="sx-icon-bx-5">
                  <div className="sx-media">
                    <i className="flaticon-telephone" />
                  </div>
                  <div className="sx-icon-bx-info">
                    <a href="tel:+15862947771" className="sx-icon-bx-title-info">Phone</a>
                    <a href="tel:+15862947771" className="sx-icon-bx-title">+1 (586) 294 7771</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 m-b30">
                <div className="sx-icon-bx-5">
                  <div className="sx-media">
                    <i className="flaticon-email-2" />
                  </div>
                  <div className="sx-icon-bx-info">
                    <a href="mailto:info@techenterprise.com" className="sx-icon-bx-title-info">Email</a>
                    <a href="mailto:info@techenterprise.com" className="sx-icon-bx-title">info@techenterprise.com</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 m-b30">
                <div className="sx-icon-bx-5">
                  <div className="sx-media">
                    <i className="flaticon-map-location" />
                  </div>
                  <div className="sx-icon-bx-info">
                    <a href="https://www.google.com/maps/dir//tech+enterprise/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x88252777d9f31efb:0xb5fd47abcd1e9b13?sa=X&ved=1t:3061&ictx=111" className="sx-icon-bx-title-info">Office</a>
                    <a href="https://www.google.com/maps/dir//tech+enterprise/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x88252777d9f31efb:0xb5fd47abcd1e9b13?sa=X&ved=1t:3061&ictx=111" className="sx-icon-bx-title">
                      31375 Harper Ave, St Clair Shores, MI 48082
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

                            <div className="col-lg-4 col-md-5 m-b30">
                                <div className="contact-home1-right">
                                    <div className="contact-home-1-form sx-bg-light">
                                        <h4 className="sx-title">Get In Touch</h4>
                                        <form className="zohoForm" onSubmit={handleSubmit}>
                                            <div className="zcwf_row">
                                                <label>First Name *</label>
                                                <input
                                                    type="text"
                                                    name="firstName"
                                                    value={formData.firstName}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="zcwf_row">
                                                <label>Last Name *</label>
                                                <input
                                                    type="text"
                                                    name="lastName"
                                                    value={formData.lastName}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="zcwf_row">
                                                <label>Email *</label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="zcwf_row">
                                                <label>Phone *</label>
                                                <input
                                                    type="text"
                                                    name="phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="zcwf_row">
                                                <label>Company *</label>
                                                <input
                                                    type="text"
                                                    name="company"
                                                    value={formData.company}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="zcwf_row">
                                                <label>Description</label>
                                                <textarea
                                                    name="description"
                                                    value={formData.description}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="zcwf_row" style={{ alignItems: 'center' }}>
                                                <input
                                                    type="checkbox"
                                                    name="consent"
                                                    id="consent"
                                                    checked={formData.consent}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <label htmlFor="consent" className="consent-label">
                                                    By providing a telephone number and submitting this form, you consent to be contacted by SMS text message. Message & data rates may apply. You can reply STOP to opt-out of further messaging.
                                                </label>
                                            </div>
                                            <div className="zcwf_row">
                                                <button type="submit">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="gmap-outline map-grayscle">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5880.646625576008!2d-82.89739362361126!3d42.527186324526184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88252777d9f31efb%3A0xb5fd47abcd1e9b13!2sTech%20Enterprises!5e0!3m2!1sen!2sus!4v1734365630559!5m2!1sen!2sus"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              title="itodomap"
          />

            </div>
        </>
    );
}

export default ContactUsPage;


