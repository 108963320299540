import { NavLink } from "react-router-dom";
import ItodoImage from "../../elements/itodo-img";
import { useState } from "react";
import { loadScript } from "../../../globals/constants";
import { useEffect } from "react";

function Header1() {

    const [isActive, setIsActive] = useState(false);

    function toggleNavClass() {
        setIsActive(!isActive)
    }

    useEffect(()=>{
        loadScript("js/mobilenav.js")
    })

    return (
        <>
            <header className={"header-style1 site-header  mobile-sider-drawer-menu " + (isActive ? "active" : "")}>
                <div className="top-bar">
                    <div className="container">
                        <div className="d-flex justify-content-between  align-content-center">
                            <div className="sx-topbar-left">
                                <ul className="e-p-bx">
                                    

                                    <li><a href="mailto:support@techenterprise.com">support@techenterprise.com</a></li>
                                    <li><a href="tel:+15862947771">+1(586)294-7771</a></li>
                                </ul>
                            </div>
                            <div className="sx-topbar-right">
                                <div className="top-bar-nav">
                                    <ul>
                                    <li>
                                    <a href="https://get.teamviewer.com/qstech" target="_blank" rel="noopener noreferrer">
                                        Remote Support
                                    </a>

                                    </li>
                                        <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
                                        <li><NavLink to="/contact-us">Contact Us</NavLink></li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky-header main-bar-wraper navbar-expand-lg">
                    <div className="main-bar">
                        <div className="container clearfix">
                            <div className="logo-header">
                                <div className="logo-header-inner logo-header-one">
                                    <NavLink to="/">
                                        <ItodoImage src="images/Logo.png" alt="" />
                                    </NavLink>
                                </div>
                            </div>
                            {/* NAV Toggle Button */}
                            <button id="mobile-side-drawer"
                                data-target=".header-nav"
                                data-toggle="collapse"
                                className="navbar-toggler collapsed"
                                onClick={toggleNavClass}
                            >
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar icon-bar-first" />
                                <span className="icon-bar icon-bar-two" />
                                <span className="icon-bar icon-bar-three" />
                            </button>
                            {/* EXTRA NAV */}
                            <div className="extra-nav">
                                <div className="extra-cell">
                                    <ul className="list-unstyled social-bx d-flex flex-wrap align-content-center">
                                        <li><a href="https://www.facebook.com/people/Tech-Enterprise/100076294227115/"><i className="feather-facebook" /> </a></li>
                                        <li><a href="https://www.linkedin.com/company/tech-enterprises"><i className="feather-linkedin" /> </a></li>
                                        <li><a href="tel:+15862947771"><i className="flaticon-phone" /> </a></li>
                                        <li><a href="mailto:info@techenterprise.com"><i className="flaticon-email" /> </a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* EXTRA Nav */}
                            {/* MAIN NAVIGATION */}
                            <div className="header-nav navbar-collapse collapse d-flex justify-content-center collapse ">
                                <ul className=" nav navbar-nav ">
                                    <li >
                                        <NavLink to="/">Home</NavLink>
                                    </li>

                                    <li>
                                        <NavLink to="/services">Our Services</NavLink>
                                    </li>

                                    <li>
                                        <NavLink to="/about-us">About Us</NavLink>
                                    </li>

                                   
                                    <li><NavLink to="/contact-us">Contact Us</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header >

        </>
    )
}



export default Header1;