import Banner from "../../sections/common/banner";
import SectionClients2 from "../../sections/company/about/section-clients1";
import SectionTeam1 from "../../sections/company/about/section-team1";
import SectionWhyChooseUs4 from "../../sections/company/about/section-why-choose-us4";

import SectionAboutCompany2 from "../../sections/company/about/section-about-company4";

import { bannerData } from "../../../globals/banner";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";

function AboutUsPage() {

    useEffect(()=>{
        loadScript("js/custom.js")
    })

    return (
        <>
            <Banner _data={bannerData.about} />
            <SectionAboutCompany2 />
            <SectionWhyChooseUs4/>
            <SectionClients2 />

        </>
    )
}

export default AboutUsPage;