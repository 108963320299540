import ItodoImage from "../../../elements/itodo-img";

function SectionClients2({ hideTitle }) {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-client-logo-1-outer sx-bg-white">
                <div className="container">
                    {/* TITLE START */}
                    {
                        (hideTitle === undefined ||
                            hideTitle === null ||
                            !hideTitle) &&
                        <div className="section-head center max-900">
                            <div className="sx-head-s-title">Powering Success with Leading Platforms</div>
                            <div className="sx-head-l-title">
                                <h2 className="sx-title2">Empowering Businesses with Leading Software Solutions</h2>
                            </div>
                        </div>
                    }
                    {/* TITLE END */}
                    <div className="section-content">
                        <div className="client-grid m-b30">
                            <div className="row justify-content-center">
                                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                    <div className="client-logo-pic">
                                        <ItodoImage src="images/client-logo/1.svg" alt="SentinelOne" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                    <div className="client-logo-pic">
                                        <ItodoImage src="images/client-logo/2.svg" alt="Microsoft" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                    <div className="client-logo-pic">
                                        <ItodoImage src="images/client-logo/6.svg" alt="Huntress" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                    <div className="client-logo-pic">
                                        <ItodoImage src="images/client-logo/8.svg" alt="Zoom" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                    <div className="client-logo-pic">
                                        <ItodoImage src="images/client-logo/5.svg" alt="NinjaOne" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                    <div className="client-logo-pic">
                                        <ItodoImage src="images/client-logo/3.svg" alt="Dropsuite" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                    <div className="client-logo-pic">
                                        <ItodoImage src="images/client-logo/7.svg" alt="Domotz" />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 col-6">
                                    <div className="client-logo-pic">
                                        <ItodoImage src="images/client-logo/4.svg" alt="Keeper" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SectionClients2;
