export const bannerData = {
    about: {
        background: "images/banner/about-banner.webp",
        title: "About Us",
        crumb: "About Us",
        watermark: "About Us"
    },
    faq: {
        background: "images/banner/bnr-4.jpg",
        title: "Faq Page",
        crumb: "Faq Page",
        watermark: "It Solution"
    },
    privacypolicy: {
        background: "images/banner/privacy-banner.webp",
        title: "Privacy & Policy",
        crumb: "Privacy & Policy",
        watermark: "Privacy & Policy"
    },

    icons: {
        background: "images/banner/bnr-4.jpg",
        title: "Icons",
        crumb: "icon-font",
        watermark: "It Solution"
    },

    services: {
        background: "images/banner/service-banner.webp",
        title: "Our Services",
        crumb: "Our Services",
        watermark: "It Solutions"
    },
    blogdetailsidebar: {
        background: "images/banner/blog.webp",
        title: "Service detail",
        crumb: "Service detail",
        watermark: "Service detail"
    },
    contact: {
        background: "images/banner/contact.webp",
        title: "Contact Us",
        crumb: "Contact Us",
        watermark: "Contact Us"
    }
}