import ItodoImage from "../../elements/itodo-img";

function SectionOurInfo2() {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-bg-light sx-our-info-outer">
                <div className="container">
                    <div className="section-content">
                        {/* Section Title */}
                        <div className="row text-center mb-5">
                            <div className="col-12">
                                <h2 className="sx-section-title">
                                    Our Comprehensive IT & Cybersecurity Solutions
                                </h2>
                                <p className="sx-section-subtitle">
                                    Delivering robust, scalable, and secure solutions tailored to your business needs.
                                </p>
                            </div>
                        </div>
                        <div className="row sx-our-info-content-wrap">
                            {/* Left Column: Managed IT Services */}
                            <div className="col-lg-4 col-md-4">
                                <div className="sx-our-info-content">
                                    <ul>
                                        <li>
                                            <div className="sx-our-info-content-list left">
                                                <h3 className="sx-bx-title"> Remote & Onsite Support</h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sx-our-info-content-list left">
                                                <h3 className="sx-bx-title">Remote Management</h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sx-our-info-content-list left">
                                                <h3 className="sx-bx-title">Patch Management</h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sx-our-info-content-list left">
                                                <h3 className="sx-bx-title">Technical Documentation</h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sx-our-info-content-list left">
                                                <h3 className="sx-bx-title">Infrastructure Management</h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sx-our-info-content-list left">
                                                <h3 className="sx-bx-title">Email Security</h3>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Center Column: Image or Graphic */}
                            <div className="col-lg-4 col-md-4">
                                <div className="sx-our-info-media-wrap">
                                    <div className="sx-our-info-media">
                                        <ItodoImage src="images/A.gif" alt="IT Services Illustration" />
                                    </div>
                                </div>
                            </div>
                            {/* Right Column: Managed Cybersecurity Services */}
                            <div className="col-lg-4 col-md-4">
                                <div className="sx-our-info-content">
                                    <ul>
                                        <li>
                                            <div className="sx-our-info-content-list right">
                                                <h3 className="sx-bx-title">Cloud Migrations</h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sx-our-info-content-list right">
                                                <h3 className="sx-bx-title">Unified Communications </h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sx-our-info-content-list right">
                                                <h3 className="sx-bx-title">Security Assessments</h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sx-our-info-content-list right">
                                                <h3 className="sx-bx-title">Penetration Testing</h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sx-our-info-content-list right">
                                                <h3 className="sx-bx-title">Cybersecurity Consulting</h3>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="sx-our-info-content-list right">
                                                <h3 className="sx-bx-title">Backup & Disaster Recovery</h3>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SectionOurInfo2;




