import { publicUrlFor } from "../../../globals/constants";
import ItodoImage from "../../elements/itodo-img";

function SectionOurInfo1() {
    return (
        <>
            <div className="section-full p-t110 p-b80 bg-white sx-about-bx3-outer">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-mv-bx1-content-wrap">
                            <div className="row">
                                {/* Left Column: Image Content */}
                                <div className="col-lg-6 col-md-12">
                                    <div className="sx-mv-bx1-media">
                                        <div className="sx-dot-bx-right">
                                            <ItodoImage src="images/about/dotted-pic.png" alt="image" />
                                        </div>

                                        <div className="sx-mv-img-outer">
                                            <div className="sx-mv-img-section-2">
                                                <ItodoImage src="images/about/sec2-main.webp" alt="image" />
                                            </div>
                                            <div className="sx-mv-img-section-1-wrap">
                                                <div
                                                    className="sx-mv-img-section-1"
                                                    style={{
                                                        backgroundImage: `url(${publicUrlFor("images/about/sec2.webp")})`,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Right Column: Text Content */}
                                <div className="col-lg-6 col-md-12">
                                    <div className="sx-about-bx1-content">
                                        <h2 className="sx-title">Why Businesses Trust Us</h2>
                                        <span className="sx-title-2">
                                            Your success is our priority, and we make IT work for you.
                                        </span>
                                        <p>
                                            For years, we've been the go-to technology partner for businesses seeking 
                                            reliable IT solutions and unparalleled cybersecurity. From keeping systems running
                                            smoothly to defending against evolving threats, we deliver results that 
                                            make a real impact on your operations.
                                        </p>
                                        <p>
                                            We don’t just solve IT problems—we anticipate them, minimize disruptions, 
                                            and help you focus on what matters: growing your business.
                                        </p>
                                        
                                        {/* Progress Metrics */}
                                        <div className="progress">
                                            <div
                                                className="progress-bar sx-bg-primary"
                                                role="progressbar"
                                                style={{ width: '95%' }}
                                                aria-valuenow={95}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <span>99.9% System Uptime</span>
                                            </div>
                                        </div>
                                        <div className="progress">
                                            <div
                                                className="progress-bar sx-bg-primary"
                                                role="progressbar"
                                                style={{ width: '75%' }}
                                                aria-valuenow={75}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <span>75% Downtime Reduction After Implementation</span>
                                            </div>
                                        </div>
                                        <div className="progress">
                                            <div
                                                className="progress-bar sx-bg-primary"
                                                role="progressbar"
                                                style={{ width: '92%' }}
                                                aria-valuenow={92}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <span>99.5% Accurate Incident Detection</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SectionOurInfo1;
