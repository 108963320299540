

import SectionCounter1 from "../../sections/home/index/section-counter1";
import ItodoImage from "../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";

function CyberPage() {

    useEffect(() => {
        loadScript("js/custom.js")
    })

    return (
        <>
            {/* service detail SECTION START */}
            <div className="section-full p-b20 sx-ser-detail-outer sx-bg-white">
                <div className="sx-ser-detail-l-pic p-b80">
                    <div className="sx-media  relative">
                        <ItodoImage src="images/services/con-banner.webp" alt="Managed Cybersecurity" />
                        <div className="sx-bnr-outline-text">
                            <h1> Managed Cybersecurity Services</h1>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="section-content">
                        <div className="sx-our-service-detail">
                            {/* INTRODUCTION START */}
                            <div className="section-head2 text-center">
                                <div className="sx-head-s-title">Your Business, Fortified</div>
                                <div className="sx-head-l-title m-b60">
                                    <h2 className="sx-title2">Stay Secure in an Evolving Cyber Threat Landscape</h2>
                                </div>
                            </div>
                            <div className="sx-our-service-info m-b60">
                                <p>
                                    In a world where cyber threats evolve daily, businesses cannot afford to take security lightly. From ransomware to data breaches, the risks are real. Our Managed Cybersecurity services are built to provide a fortress of protection for your digital assets, ensuring your business stays operational, compliant, and ahead of the curve.
                                </p>
                                <p>
                                    With a proactive and multilayered approach to cybersecurity, we safeguard your infrastructure, employees, and data. Whether you are an SMB or a large enterprise, we tailor our services to meet your unique needs and deliver peace of mind.
                                </p>
                            </div>
                            {/* INTRODUCTION END */}

                            {/* CORE SERVICES SECTION */}
                            <div className="section-full  p-b80 sx-bg-light-gray">
                                <div className="container">
                                    <h3 className="sx-title3 text-center m-b40">What We Offer</h3>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                            <div className="sx-iconbox-wrap">

                                                <div className="sx-iconbox-content">
                                                    <h4 className="sx-iconbox-title">24/7 Security Operations Center (SOC)</h4>
                                                    <p>
                                                        Around-the-clock monitoring of your systems to detect, analyze, and respond to potential threats in real-time.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="sx-iconbox-wrap">

                                                <div className="sx-iconbox-content">
                                                    <h4 className="sx-iconbox-title">Incident Detection and Response</h4>
                                                    <p>
                                                        Rapid response strategies to mitigate the impact of security incidents and ensure minimal disruption.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="sx-iconbox-wrap">

                                                <div className="sx-iconbox-content">
                                                    <h4 className="sx-iconbox-title">Security Awareness Training</h4>
                                                    <p>
                                                        Equip your employees with knowledge to recognize and prevent phishing, malware, and other cyber attacks.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="sx-iconbox-wrap">

                                                <div className="sx-iconbox-content">
                                                    <h4 className="sx-iconbox-title">Backup & Disaster Recovery</h4>
                                                    <p>
                                                        Protect your data and ensure business continuity with reliable backup solutions and recovery strategies.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="sx-iconbox-wrap">

                                                <div className="sx-iconbox-content">
                                                    <h4 className="sx-iconbox-title">Penetration Testing</h4>
                                                    <p>
                                                        Proactively identify vulnerabilities with in-depth penetration testing and actionable recommendations.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="sx-iconbox-wrap">

                                                <div className="sx-iconbox-content">
                                                    <h4 className="sx-iconbox-title">Cybersecurity Consulting</h4>
                                                    <p>
                                                        Expert advice to build and implement a tailored cybersecurity strategy for your organization.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        
                                    </div>

                                    
                                </div>
                                
                            </div>
{/* WHY CHOOSE US SECTION */}
<div className="section-full p-b50">
    <div className="container">
        <h3 className="sx-title3 text-center m-b40">Why Choose Our Cybersecurity Services?</h3>
        <p>
            We understand the critical importance of cybersecurity for modern businesses, and that’s why organizations trust us to safeguard their digital assets. Our services include proactive monitoring combined with real-time threat intelligence to ensure potential risks are identified and addressed before they impact your operations. We deliver comprehensive solutions tailored to the unique requirements of your industry, providing robust protection against emerging cyber threats.
        </p>
        <p>
            Our team of certified cybersecurity professionals brings a wealth of expertise to every engagement, ensuring your systems and data remain secure. Our scalable services are designed to grow alongside your business, offering flexibility and reliability as your needs evolve. With a focus on protecting your business, we deliver solutions that enable you to operate with confidence in an ever-changing threat landscape.
        </p>
    </div>
</div>

                            
                            <SectionCounter1 />
                        </div>
                    </div>
                </div>
            </div>
            {/* service detail SECTION END */}
            
        </>
    )
}

export default CyberPage;
