import CountUp from "react-countup";

function SectionCounter1() {
    return (
        <>
            <div className="counter-blocks">
                <div className="row when-item-four">
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div className="sx-count">
                            <h2 className="sx-title">
                                <span className="sx-cont-before">+</span>
                                <span className="counter">
                                    <CountUp end={2} duration={2} />
                                </span>
                                <span className="sx-cont-after">K</span>
                            </h2>
                            <div className="sx-count-info">Successful Projects</div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div className="sx-count">
                            <h2 className="sx-title">
                                <span className="sx-cont-before"></span>
                                <span className="counter">
                                    <CountUp end={27} duration={5} />
                                </span>
                                <span className="sx-cont-after"></span>
                            </h2>
                            <div className="sx-count-info">Industries Supported</div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div className="sx-count">
                            <h2 className="sx-title">
                                <span className="sx-cont-before"></span>
                                <span className="counter">
                                    <CountUp end={37} duration={4} />
                                </span>
                            </h2>
                            <div className="sx-count-info">Winning Award</div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div className="sx-count">
                            <h2 className="sx-title">
                                <span className="sx-cont-before">+</span>
                                <span className="counter">
                                    <CountUp end={40} duration={5} />
                                </span>
                            </h2>
                            <div className="sx-count-info">Years in Business</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionCounter1;