import SectionClients2 from "../../sections/home/index/section-clients1"
import ItodoImage from "../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";

function ConsultingPage() {

    useEffect(() => {
        loadScript("js/custom.js")
    })

    return (
        <>
            {/* service detail SECTION START */}
            <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
                <div className="sx-ser-detail-l-pic p-b80">
                    <div className="sx-media relative">
                        <ItodoImage src="images/services/cyber-banner.webp" alt="IT Projects & Consulting" />
                        <div className="sx-bnr-outline-text">
                            <h1> IT Projects & Consulting</h1>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="section-content">
                        <div className="sx-our-service-detail">
                            {/* INTRODUCTION */}
                            <div className="section-head2">
                                <div className="sx-head-s-title">Plan. Build. Transform.</div>
                                <div className="sx-head-l-title">
                                    <h2 className="sx-title2">IT Solutions That Drive Business Growth</h2>
                                </div>
                            </div>
                            <div className="sx-our-service-info p-b20">
                                <p>
                                    In a fast-evolving technological landscape, your IT strategy needs to align with your business objectives to ensure success. Whether you are migrating to the cloud, implementing unified communications, or setting up a robust network infrastructure, our IT Projects & Consulting services provide the expertise and planning necessary to execute seamlessly.
                                </p>
                                <p>
                                    Our team collaborates with you to analyze your current systems, understand your challenges, and deliver solutions tailored to your specific needs. With our strategic consulting and hands-on project execution, we empower your business to innovate, scale, and thrive in a competitive market.
                                </p>
                            </div>
                    </div>

                            {/* FULL-PAGE SECTION WITH DETAILS */}
                            <div className="section-full p-t10 p-b80 sx-bg-light-gray" style={{ backgroundColor: '#ded7d7' }}>
                                <div className="container">
                                    <h3 className="sx-title3 text-center m-b50">Our IT Projects & Consulting Services</h3>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 m-b30">
                                            <h4>Microsoft 365 Migrations</h4>
                                            <p>
                                                Upgrade your business to the industry-leading suite of productivity tools. Our experts handle every aspect of your Microsoft 365 migration, from planning to deployment, ensuring a seamless transition with minimal disruption.
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-6 m-b30">
                                            <h4>Cloud Migrations</h4>
                                            <p>
                                                Take your business to the next level with secure and efficient cloud migration services. Whether moving to Azure, AWS, or a hybrid solution, we optimize your cloud strategy to reduce costs and improve scalability.
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-6 m-b30">
                                            <h4>Business Phone Systems</h4>
                                            <p>
                                                Enhance communication with state-of-the-art phone systems tailored to your business needs. From VoIP solutions to advanced call-routing features, we help you improve customer and internal communications.
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-6 m-b30">
                                            <h4>Structured Network Cabling</h4>
                                            <p>
                                                Lay the foundation for success with professional-grade network cabling. We design and install structured cabling systems to improve data transmission speeds and simplify maintenance.
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-6 m-b30">
                                            <h4>Wired & Wireless Networking</h4>
                                            <p>
                                                Build a reliable and high-performance network infrastructure with our wired and wireless solutions. From setting up secure connections to optimizing performance, we ensure your business stays connected at all times.
                                            </p>

                                        </div>
                                        <div className="col-lg-4 col-md-6 m-b30">
                                            <h4>Business Continuity Planning</h4>
                                            <p>
                                                Prepare for the unexpected with a robust business continuity plan. Our experts help you develop strategies to minimize downtime and keep your operations running smoothly during disruptions.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* WHY CHOOSE OUR IT CONSULTING? */}
                            <div className="section-full p-t50 p-b50">
                                <div className="container text-center">
                                    <h3 className="sx-title3 text-center m-b40">Why Partner with Us for IT Projects & Consulting?</h3>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <p>
                                            Partner with a team of experienced IT professionals who excel in strategy, implementation, and project management. We take a collaborative approach, embedding ourselves to your team to thoroughly understand your goals and challenges. By crafting tailored solutions that align with your goals, we ensure every project is designed to meet your needs. Whether it’s cloud migrations, network infrastructure, or unified communications, we bring depth of expertise and a commitment to excellence that sets us apart.                                            </p>
                                            
                                        </div>
                                        <div className="col-lg-6">
                                            <p>
                                            Our focus is on delivering projects on time, within budget, and with minimal disruption to your operations. Utilizing the latest IT technologies and industry best practices, we create cost-effective solutions that provide measurable ROI. Beyond project delivery, we’re dedicated to offering exceptional customer support and ongoing guidance to ensure your systems remain efficient, secure, and adaptable to future growth. With us, you’re not just getting IT services — you’re gaining a long-term partner in success.
                                             </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* CALL TO ACTION */}
                            <div className="section-full p-t50 sx-bg-light-gray">
                                <div className="container text-center">
                                    <h3 className="sx-title3">Let’s Build Your Future Together</h3>
                                    <p>
                                        Whether you’re launching a new IT project or need expert guidance on your digital transformation journey, we’re here to help. With our proven expertise and commitment to excellence, your IT goals are within reach.
                                    </p>
                                    <p>
                                        <strong>Contact us today</strong> to schedule a consultation and explore how our IT Projects & Consulting services can transform your business.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
         
            </div>
            {/* service detail SECTION END */}
            
            <SectionClients2 />
        </>
    )
}



export default ConsultingPage;