import ItodoImage from "../../../elements/itodo-img";

function SectionAboutCompany2Inner() {
    return (
        <>
            <div className="sx-about-bx5-content-wrap">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="sx-about-bx3-media">
                            <div className="sx-dot-bx1"><ItodoImage src="images/about/dotted-pic2.png" alt="image" /></div>
                            <div className="sx-dot-bx2"><ItodoImage src="images/about/dotted-pic2.png" alt="image" /></div>
                            <div className="sx-ab3-img-outer">
                                <div className="sx-ab3-media">
                                    <ItodoImage src="images/about/sec1.webp" alt="media" />
                                </div>
                            </div>
                            <div className="sx-ab-circle-box">
                                <div className="sx-ab-circle-box-content">
                                    <span className="sx-exp">40+ Years Experience</span>
                                    <div className="sx-exp-title-outer">
                                        <span className="sx-exp-title-1">IT</span>
                                        <span className="sx-exp-title-2">Solutions</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="sx-about-bx3-content">
                            <h2 className="sx-title">Your Trusted Partner in IT Solutions.</h2>
                            <span className="sx-title-2">Delivering innovative IT, cybersecurity, and consulting services to empower businesses.</span>
                            <p>For over 40 years, we’ve been at the forefront of IT innovation, providing Managed IT Services, Managed Cybersecurity, and IT Projects & Consulting. Our mission is to simplify technology and make it a driving force for growth and security for businesses of all sizes.</p>
                            <p>From unlimited remote and onsite support to 24/7 security monitoring and cutting-edge IT projects, we deliver tailored solutions that meet your unique business needs. Trust us to handle your IT infrastructure, cybersecurity, and strategic technology goals with precision and expertise.</p>
                            <div className="row sx-about-icon-bx3-column">
                                <div className="col-lg-6 col-md-6">
                                    <div className="sx-icon-bx-3">
                                        <div className="sx-media">
                                            <i className="flaticon-incoming-call" />
                                        </div>
                                        <div className="sx-icon-bx-info">
                                            <span className="sx-icon-bx-title-info">Contact Phone</span>
                                            <span className="sx-icon-bx-title">+1 (586) 294-7771</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="sx-icon-bx-3">
                                        <div className="sx-media">
                                            <i className="flaticon-email-1" />
                                        </div>
                                        <div className="sx-icon-bx-info">
                                            <span className="sx-icon-bx-title-info">Contact Mail</span>
                                            <span className="sx-icon-bx-title">info@techenterprise.com</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hilite-title text-left p-l50 text-uppercase">
                <strong>About Us</strong>
            </div>
        </>
    )
}

export default SectionAboutCompany2Inner;
