import SectionCounter1 from "../../sections/home/index/section-counter1";
import ItodoImage from "../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";

function ManagedITPage() {

    useEffect(()=>{
        loadScript("js/custom.js")
    })

    return (
        <>
            {/* service detail SECTION START */}
            <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
                <div className="sx-ser-detail-l-pic p-b80">
                    <div className="sx-media  relative ">
                        <ItodoImage src="images/services/it-banner.webp" alt="Managed IT" />
                        <div className="sx-bnr-outline-text">
                            <h1> Managed IT Solution</h1>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="section-content">
                        <div className="sx-our-service-detail">
                            {/* TITLE START */}
                            <div className="section-head2">
                                <div className="sx-head-s-title">Why Choose Us</div>
                                <div className="sx-head-l-title">
                                    <h2 className="sx-title2">Empowering Businesses with Scalable and Reliable IT Solutions</h2>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="sx-our-service-info m-b80">
                                <p>In today’s fast-paced digital landscape, businesses require seamless IT management to stay ahead of the competition. Our Managed IT services provide comprehensive support to optimize your IT infrastructure, ensuring uninterrupted operations, enhanced security, and improved productivity.</p>
                                <p>Whether you’re looking for 24/7 remote support, proactive monitoring, or cutting-edge automation, our solutions are designed to fit your unique business needs. We take the hassle out of IT management, so you can focus on growing your business with peace of mind.</p>
                                
                                <h3>Our Comprehensive Managed IT Services Include:</h3>
                                <ul>
                                    <li><strong>Unlimited Remote & Onsite Support:</strong> Get quick resolutions to IT issues with our experienced technicians available round the clock.</li>
                                    <li><strong>Remote Monitoring and Management (RMM):</strong> Proactively monitor and manage your systems to detect and resolve issues before they disrupt your operations.</li>
                                    <li><strong>Patch Management:</strong> Stay updated and secure with automated patching of your devices and applications.</li>
                                    <li><strong>IT Automation:</strong> Streamline repetitive tasks to improve efficiency and reduce errors.</li>
                                    <li><strong>Technical Documentation:</strong> Detailed documentation ensures complete transparency and compliance for your IT environment.</li>
                                    <li><strong>Infrastructure Management:</strong> Comprehensive management of your servers, networks, and devices to maintain a robust IT ecosystem.</li>
                                    <li><strong>Email Security:</strong> Protect your communications with advanced email filtering, encryption, and anti-phishing tools.</li>
                                </ul>

                                <h3>Benefits of Choosing Our Managed IT Services:</h3>
                                <p>Partnering with us means you’ll have access to advanced IT expertise, innovative technologies, and reliable support that drives business success. Our solutions are tailored to your requirements, offering flexibility, scalability, and cost-efficiency.</p>
                                
                                <div className="row sx-about-icon-bx1-column p-t50 p-b20">
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-mouse-1" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">24/7 IT Support</span>
                                                <span className="sx-icon-bx-title-info">Minimize downtime with constant monitoring and assistance.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-download" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Proactive Monitoring</span>
                                                <span className="sx-icon-bx-title-info">Identify and resolve issues before they impact your business.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-wordpress" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Customized Solutions</span>
                                                <span className="sx-icon-bx-title-info">Tailored IT strategies that align with your business goals.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <h3>Start Your IT Transformation Today!</h3>
                                <p>With our Managed IT services, you can enhance operational efficiency, strengthen your cybersecurity defenses, and future-proof your technology infrastructure. Our team is dedicated to delivering measurable results that enable your business to thrive in a competitive market.</p>
                            </div>
                            
                            <SectionCounter1 />
                        </div>
                    </div>
                </div>
            </div>
            {/* service detail SECTION END */}

        </>
    )
}

export default ManagedITPage;
