import { Route, Routes, Navigate } from "react-router-dom"; // Add Navigate here
import AboutUsPage from "../app/components/company/about-us";
import IconsPage from "../app/components/company/icons";


import ServicesPage from "../app/components/services/services";

import ContactUsPage from "../app/components/contactus/contact-us";
import Home1Page from "../app/components/home/index";


import PrivacyPolicy from "../app/components/company/privacy-policy";


import ManagedITPage from "../app/components/services/managed-it";
import CyberPage from "../app/components/services/cybersecurity";
import ConsultingPage from "../app/components/services/consulting";









function AppRoutes() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home1Page />} />
                <Route path="/index" element={<Navigate to="/" replace />} /> {/* Redirect to "/" */}
                <Route path="/about-us" element={<AboutUsPage />} />
                <Route path="/icons" element={<IconsPage />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/contact-us" element={<ContactUsPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />




                <Route path="/services/managed-it" element={<ManagedITPage />} />
                <Route path="/services/cybersecurity" element={<CyberPage />} />
                <Route path="/services/consulting" element={<ConsultingPage />} />

{/* 
                <Route path="/services/infrastructure-management" element={<IMServicePage />} />
                <Route path="/services/backup-and-diaster-recovery" element={<BDRServicePage />} />
                <Route path="/services/security-assessments" element={<SAServicePage />} />
                <Route path="/services/business-continuity-planning" element={<BCPServicePage />} />
                <Route path="/services/cloud-migration" element={<CMServicePage />} />
                <Route path="/services/unified-communications" element={<UCServicePage />} />
*/}



            </Routes>
        </>
    )
}

export default AppRoutes;