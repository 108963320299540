import { NavLink } from "react-router-dom";
import ItodoImage from "../../elements/itodo-img";

function Footer() {
    const currentYear = new Date().getFullYear(); // Get the current year dynamically
    return (
        <>
            <footer className="site-footer footer-dark">
                {/* FOOTER BLOCKES START */}
                <div className="footer-top ">
                    <div className="container">
                        <div className="row">
                            {/* ABOUT COMPANY */}
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 ">
                                <div className="widget widget_about">
                                    <div className="logo-footer clearfix p-b15">
                                        <NavLink to="/" className="sx-text-primary"><ItodoImage src="images/footer-logo.png" alt="#" className="footer-img" /></NavLink>
                                    </div>
                                    <p>Established in 1984, we have earned a platinum reputation in 
                                        today’s business communication world.
                                    </p>
                                    <div className="widget_about-call-section">
                                        <div className="sx-f-call-icon"><i className="flaticon-telephone" /></div>
                                        <div className="sx-f-call-section">
                                            <span>Contact us 24/7</span>
                                            <a href="tel:+1(586)2947771">+1 (586) 294-7771</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* EXPLORE LINKS */}
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 col">
                                <div className="widget widget_services">
                                    <h4 className="sx-f-title">Explore Links</h4>
                                    <ul>
                                    <li><NavLink to="/">Home</NavLink></li>
                                        <li><NavLink to="/services">Our Services</NavLink></li>
                                        <li><NavLink to="/about-us">About us</NavLink></li>
                                        <li><NavLink to="/contact-us">Contact us</NavLink></li>
                                        
                                    </ul>
                                </div>
                            </div>
                            {/* USEFUL LINK */}
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 col">
                                <div className="widget widget_services">
                                    <h4 className="sx-f-title">Useful Links</h4>
                                    <ul>
                                    <li>
                                            <a href="mailto:info@techenterprise.com"> Get Support</a>
                                        </li>
                                    <li>
                                        <a href="https://get.teamviewer.com/qstech" target="_blank" rel="noopener noreferrer">
                                            Remote Support
                                        </a>
                                    </li>
                                        <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>

                                    </ul>
                                </div>
                            </div>
                            {/* SOLUTIONS */}
                            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 col footer-col-3">
                                <div className="widget widget_info">
                                    <h4 className="sx-f-title">Solutions</h4>
                                    <ul>
                                        <li>
                                            <div className="widget-info-icon">
                                                <i className="flaticon-phone" />
                                            </div>
                                            <div className="widget-info-detail">
                                                <a href="tel:+1(586)2947771">+1 (586) 294-7771</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="widget-info-icon">
                                                <i className="flaticon-email" />
                                            </div>
                                            <div className="widget-info-detail">
                                            <a href="mailto:info@techenterprise.com"> info@techenterprise.com</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="widget-info-icon">
                                                <i className="flaticon-maps-and-flags" />
                                            </div>
                                            <div className="widget-info-detail">
                                                <p>31375 Harper Ave, St Clair Shores, MI 48082</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* CONTACT US */}
                        </div>
                    </div>
                </div>
                <div className="sx-f-bottom-section">
                    <div className="sx-f-social">
                        <ul>
                        <li><a href="https://www.facebook.com/people/Tech-Enterprise/100076294227115/"><i className="feather-facebook" /> </a></li>
                        <li><a href="https://www.linkedin.com/company/tech-enterprises"><i className="feather-linkedin" /> </a></li>
                        <li><a href="tel:+15862947771"><i className="flaticon-phone" /> </a></li>
                        <li><a href="mailto:info@techenterprise.com"><i className="flaticon-email" /> </a></li>
                        </ul>
                    </div>
                    <div className="sx-f-copy">
                        © {currentYear} by <NavLink to="/">Tech Enterprises.</NavLink> All rights reserved.
                    </div>
                </div>
                {/* FOOTER COPYRIGHT */}
            </footer>

        </>
    )
}

export default Footer;