import { NavLink } from "react-router-dom";

function SectionWhyChooseUs1() {
    return (
        <>
            <div
                className="section-full p-t110 p-b80 sx-why-chooseus-outer sx-bg-light bg-cover"
                style={{ backgroundColor: "#ded7d7" }}
            >
                <div className="container">
                    {/* TITLE START */}
                    <div className="section-head center">
                        <div className="sx-head-s-title">Why Choose Us</div>
                        <div className="sx-head-l-title">
                            <h2 className="sx-title">Tailored IT Solutions for Every Business Need</h2>
                        </div>
                    </div>
                    {/* TITLE END */}

                    <div className="section-content">
                        <div className="sx-icon-bx-wrap">
                            <div className="row">
                                {/* Block One */}
                                <div
                                    className="col-lg-4 col-md-6 wow fadeInDown"
                                    data-wow-duration="1000ms"
                                >
                                    <div className="sx-icon-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-icon-bx-icon">
                                                <span className="sx-text-primary">
                                                    <i className="flaticon-data" />
                                                </span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Infrastructure Management</h4>
                                                <p>
                                                    Ensure your IT infrastructure runs smoothly with
                                                    proactive management, regular patching, real-time
                                                    monitoring, and powerful automation.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Block Two */}
                                <div
                                    className="col-lg-4 col-md-6 wow fadeInDown"
                                    data-wow-duration="1000ms"
                                >
                                    <div className="sx-icon-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-icon-bx-icon">
                                                <span className="sx-text-primary">
                                                    <i className="flaticon-programmer" />
                                                </span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Backup & Disaster Recovery</h4>
                                                <p>
                                                    Protect critical data and maintain business
                                                    continuity with secure, automated backups and
                                                    comprehensive disaster recovery strategies.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Block Three */}
                                <div
                                    className="col-lg-4 col-md-6 wow fadeInDown"
                                    data-wow-duration="1000ms"
                                >
                                    <div className="sx-icon-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-icon-bx-icon">
                                                <span className="sx-text-primary">
                                                    <i className="flaticon-email-2" />
                                                </span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Unified Communications</h4>
                                                <p>
                                                    Improve team collaboration and communication with
                                                    modern, integrated systems that keep your business
                                                    connected across devices.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Block Four */}
                                <div
                                    className="col-lg-4 col-md-6 wow fadeInDown"
                                    data-wow-duration="1000ms"
                                >
                                    <div className="sx-icon-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-icon-bx-icon">
                                                <span className="sx-text-primary">
                                                    <i className="flaticon-profit" />
                                                </span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Business Continuity Planning</h4>
                                                <p>
                                                    Develop strategies that keep your business running
                                                    smoothly during disruptions, minimizing downtime
                                                    and data loss.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Block Five */}
                                <div
                                    className="col-lg-4 col-md-6 wow fadeInDown"
                                    data-wow-duration="1000ms"
                                >
                                    <div className="sx-icon-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-icon-bx-icon">
                                                <span className="sx-text-primary">
                                                    <i className="flaticon-cloud-computing" />
                                                </span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Cloud Migrations</h4>
                                                <p>
                                                    Simplify your transition to the cloud with seamless
                                                    migrations to platforms like Microsoft 365 for
                                                    improved productivity and flexibility.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Block Six */}

                                <div
                                    className="col-lg-4 col-md-6 wow fadeInDown"
                                    data-wow-duration="1000ms"
                                >
                                    <div className="sx-icon-bx-1">
                                        <div className="sx-icon-box-wraper">
                                            <div className="sx-icon-bx-icon">
                                                <span className="sx-text-primary">
                                                    <i className="flaticon-security" />
                                                </span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="sx-tilte">Security Assessments</h4>
                                                <p>
                                                    Identify and address vulnerabilities with expert
                                                    security assessments, penetration testing, and
                                                    actionable insights to improve your defenses.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                

                                {/* Final Note */}
                                <div className="col-lg-12 text-center p-t20">
                                    <p>
                                        Our comprehensive IT services ensure your business has the
                                        tools, strategies, and support it needs to grow, stay
                                        secure, and adapt to any challenge.
                                    </p>
                                    <NavLink to="/contact-us" className="btn btn-primary m-t10">
                                        Contact us to learn more
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SectionWhyChooseUs1;
