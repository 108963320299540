import { NavLink } from "react-router-dom";
import { publicUrlFor } from "../../../../globals/constants";
import { register } from 'swiper/element/bundle';
register();

function SectionSlider3() {
    return (
        <>
            <div className="sx-bnr-3-wrap-outer home-3-slider">
                {/* swiper slides */}
                <swiper-container
                    loop="true"
                    space-between="30"
                    effect="fade"
                    navigation-next-el=".swiper-button-next"
                    navigation-perv-el=".swiper-button-prev"
                    pagination-el=".swiper-pagination"
                    pagination-clickable="true"
                    parallax="true"
                    autoplay-delay="4000"
                    autoplay-disable-on-interaction="true"
                    class="swiper-wrapper"
                >
                    <swiper-slide class="sx-bnr-3-wrap swiper-slide overlay-overlay" style={{ backgroundImage: `url(${publicUrlFor("images/main-slider/slider3/slider1.webp")})` }}>
                        <div className="sx-bg-overlay opacity-0 sx-bg-primary" />
                        <div className="container">
                            <div className="sx-bnr-3-content">
                                <span className="sx-bnr-3-small-title">Proactive IT Management</span>
                                <h2 className="sx-bnr-3-large-title">We Handle IT, So You Can Focus on Business</h2>
                                <div className="sx-bnr-readmore">
                                    <NavLink to="/about-us" className="site-button sx-btn-primary icon">
                                        <i className="fa  fa-long-arrow-right" />
                                        Learn More About Us
                                    </NavLink>
                                </div>
                               
                            </div>
                        </div>
                        <div className="sx-bnr-text-masking large">
                            <div className="light">
                                <h1 className="sx-title">IT</h1>
                            </div>
                        </div>
                    </swiper-slide>

                    <swiper-slide class="sx-bnr-3-wrap swiper-slide overlay-overlay" style={{ backgroundImage: `url(${publicUrlFor("images/main-slider/slider3/slider2.webp")})` }}>
                        <div className="sx-bg-overlay opacity-0 sx-bg-primary" />
                        <div className="container">
                            <div className="sx-bnr-3-content">
                                <span className="sx-bnr-3-small-title">Future-Ready IT Services</span>
                                <h2 className="sx-bnr-3-large-title">Simplifying Your Journey to the Cloud</h2>
                                <div className="sx-bnr-readmore">
                                    <NavLink to="/services" className="site-button sx-btn-primary icon">
                                        <i className="fa  fa-long-arrow-right" />
                                        Discover Our Services
                                    </NavLink>
                                </div>
                               
                            </div>
                        </div>
                        <div className="sx-bnr-text-masking large">
                            <div className="light">
                                <h1 className="sx-title">IT</h1>
                            </div>
                        </div>
                    </swiper-slide>

                    
                    <swiper-slide class="sx-bnr-3-wrap swiper-slide overlay-overlay" style={{ backgroundImage: `url(${publicUrlFor("images/main-slider/slider3/slider3.webp")})` }}>
                        <div className="sx-bg-overlay opacity-0 sx-bg-primary" />
                        <div className="container">
                            <div className="sx-bnr-3-content">
                                <span className="sx-bnr-3-small-title">A Legacy of Excellence</span>
                                <h2 className="sx-bnr-3-large-title">Delivering IT Solutions Since 1984</h2>
                                <div className="sx-bnr-readmore">
                                    <NavLink to="/about-us" className="site-button sx-btn-primary icon">
                                        <i className="fa  fa-long-arrow-right" />
                                        Learn About Us
                                    </NavLink>
                                </div>
                               
                            </div>
                        </div>
                        <div className="sx-bnr-text-masking large">
                            <div className="light">
                                <h1 className="sx-title">IT</h1>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide class="sx-bnr-3-wrap swiper-slide overlay-overlay" style={{ backgroundImage: `url(${publicUrlFor("images/main-slider/slider3/slider4.webp")})` }}>
                        <div className="sx-bg-overlay opacity-0 sx-bg-primary" />
                        <div className="container">
                            <div className="sx-bnr-3-content">
                                <span className="sx-bnr-3-small-title">Trusted IT Partner</span>
                                <h2 className="sx-bnr-3-large-title">Empowering Businesses with Seamless IT Solutions</h2>
                                <div className="sx-bnr-readmore">
                                    <NavLink to="/contact-us" className="site-button sx-btn-primary icon">
                                        <i className="fa  fa-long-arrow-right" />
                                        Get Started Today
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="sx-bnr-text-masking large">
                            <div className="light">
                                <h1 className="sx-title">do!</h1>
                            </div>
                        </div>
                    </swiper-slide>


                </swiper-container>
                {/* !swiper slides */}

            </div>
        </>
    )
}

export default SectionSlider3;