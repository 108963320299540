import Banner from "../../sections/common/banner";
import SectionWhyChooseUs1 from "../../sections/home/index/section-why-choose-us1";
import SectionAboutCompany1 from "../../sections/services/section-about-company1";
import { bannerData } from "../../../globals/banner";
import { useEffect } from "react";
import { loadScript } from "../../../globals/constants";
import SectionOurInfo2 from "../../sections/services/section-our-info2";
import SectionOurInfo1 from "../../sections/services/section-our-info1";

function ServicesPage() {

    useEffect(()=>{
        loadScript("js/custom.js")
    })

    return (
        <>
            <Banner _data={bannerData.services} />
            <SectionWhyChooseUs1 />
            <SectionAboutCompany1 />

            <SectionOurInfo2 />
            <SectionOurInfo1 />


        </>
    )
}

export default ServicesPage;