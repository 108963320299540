import SectionAboutCompany2Inner from "../about/section-about-company2-inner";
import SectionServices1 from "../../../sections/home/index/section-services1";

function SectionAboutCompany4() {
    return (
        <>
            <div className="section-full  p-t110 p-b80 bg-white sx-about-bx3-outer">
                <div className="container">
                    <div className="section-content">
                        <SectionAboutCompany2Inner />
                        <SectionServices1/>
                        {/* Info START */}
                        {/* Info  END */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionAboutCompany4;