import ItodoImage from "../../../elements/itodo-img";
import { NavLink } from "react-router-dom";
import SectionTestimonials2 from "../about/section-testimonials2";

export function SectionWhyChooseUs2Inner() {
    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <div className="sx-about-bx4-media">
                        <div className="sx-ab4-img-outer">
                            <div className="sx-ab4-media">
                                <ItodoImage src="images/about/globe.webp" alt="media" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="sx-about-bx4-content">
                        <h2 className="sx-title">Why Choose Us as Your IT Partner?</h2>
                        <p>Empowering businesses with reliable IT solutions, cutting-edge cybersecurity, and tailored strategies for over 40 years.</p>
                        <ul className="sx-arrow-circle-checked">
                            <li>24/7 IT Support: Always available to keep your systems running.</li>
                            <li>Advanced Cybersecurity: Protecting your data and reputation.</li>
                            <li>Custom Solutions: Strategies tailored to your business needs.</li>
                            <li>Proven Success: Over 2,000 successful projects completed.</li>
                            <li>Cost-Efficient Plans: Maximize productivity and reduce downtime.</li>
                        </ul>
                        <NavLink to="/services" className="site-button sx-btn-primary icon sx-btn-lg">
                            <i className="fa fa-long-arrow-right" />
                            Explore Our IT Solutions
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    );
}

function SectionWhyChooseUs2() {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-bg-light sx-about-bx4-outer">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-about-bx4-content-wrap p-b70">
                            <SectionWhyChooseUs2Inner />
                        </div>
                        {/*testimonial section */}
                        <SectionTestimonials2 />
                    </div>
                </div>
            </div>
        </>
    );
}

export default SectionWhyChooseUs2;
