import ItodoImage from "../../elements/itodo-img";

function SectionAboutCompany1() {
    return (
        <>
            <div className="section-full p-t110 p-b80 sx-bg-white sx-about-bx1-outer">
                <div className="container">
                    <div className="section-content">
                        <div className="sx-about-bx5-content-wrap">
                            <div className="row">
                                {/* Left Column: Text Content */}
                                <div className="col-lg-6 col-md-12 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-about-bx1-content">
                                        <h2 className="sx-title">Expert IT Services Tailored for Your Business</h2>
                                        <p>
                                            Our IT Projects and Consulting services are designed to take the complexity out of technology. Whether you need seamless migrations, resilient business strategies, or enhanced communication solutions, our team is here to help you succeed.
                                        </p>

                                        {/* Key Focus Areas */}
                                        <div className="row sx-about-icon-bx1-column">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="sx-icon-bx-2">
                                                    <div className="sx-icon-bx-info">
                                                        <span className="sx-icon-bx-title">Microsoft 365 Migration</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="sx-icon-bx-2">
                                                    <div className="sx-icon-bx-info">
                                                        <span className="sx-icon-bx-title">Cloud Migration</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row sx-about-bx1-column">
                                            <div className="col-lg-6 col-md-6">
                                                <p>
                                                    Transition your business to Microsoft 365 with a stress-free process led by our experts.
                                                </p>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <p>
                                                    Optimize performance with secure and efficient cloud migration solutions.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="row sx-about-icon-bx1-column">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="sx-icon-bx-2">
                                                    <div className="sx-icon-bx-info">
                                                        <span className="sx-icon-bx-title">Business Continuity</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="sx-icon-bx-2">
                                                    <div className="sx-icon-bx-info">
                                                        <span className="sx-icon-bx-title">Unified Communication </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row sx-about-bx1-column">
                                            <div className="col-lg-6 col-md-6">
                                                <p>
                                                    Ensure business resilience with reliable continuity planning and disaster recovery strategies.
                                                </p>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <p>
                                                    Enhance collaboration and communication with advanced phone systems and unified platforms.
                                                </p>
                                            </div>
                                        </div>

                                        {/* Progress Bar */}
                                        <div className="progress">
                                            <div
                                                className="progress-bar sx-bg-primary"
                                                role="progressbar"
                                                style={{ width: '98%' }}
                                                aria-valuenow={98}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                <span>98% Client Satisfaction Rate</span>
                                            </div>
                                        </div>

                                        <span className="sx-title-2">
                                            Trusted by businesses of all sizes for over 40 years.
                                        </span>
                                    </div>
                                </div>

                                {/* Right Column: Image Content */}
                                <div className="col-lg-6 col-md-12 wow fadeInDown" data-wow-duration="1000ms">
                                    <div className="sx-about-bx1-media">
                                        <div className="sx-dot-bx">
                                            <ItodoImage src="images/about/dotted-pic.png" alt="" />
                                        </div>
                                        <div className="sx-ab-img-outer">
                                            <div className="sx-ab-img-section-1-wrap"></div>
                                            <div className="sx-ab-img-section-2">
                                                <ItodoImage src="images/services/consol.webp" alt="IT Services and Consulting" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SectionAboutCompany1;
